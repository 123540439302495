import { alpha, Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import DropdownIcon from "../../components/FormSearch/icons/DropdownIcon";
import { useRecoilState, useRecoilValue } from "recoil";
import { totalGuestsCounterSelector } from "../../../state/booking-state";
import {
  dateRangeAtom,
  IDateRangeSerialized,
  selectedCityIdAtom,
  selectedCityNameAtom,
} from "../../../state/search-state";
import { getLocale } from "../../../fe-ui/utils/getLocale";
import dayjs from "dayjs";
import { useLocale } from "next-intl";
import { useEffect } from "react";
import { useGetCities } from "../../../api/useGetCities";

type Props = {
  onClick: () => void;
};

const formatDateRange = (dateRange: IDateRangeSerialized): string | null => {
  const locale = getLocale();

  if (dateRange[0] == null || dateRange[1] == null) {
    return null;
  }

  const startDate = dayjs(dateRange[0]).locale(locale);
  const endDate = dayjs(dateRange[1]).locale(locale);
  const startDateDayAndMonth = startDate.format("DD MMM");
  const endDateDayAndMonth = endDate.format("DD MMM");

  if (startDate.year() !== endDate.year()) {
    // different years
    return `${startDateDayAndMonth} ${startDate.year()} - ${endDateDayAndMonth} ${endDate.year()}`;
  } else if (endDate.year() === dayjs().year()) {
    // both current year
    return `${startDateDayAndMonth} - ${endDateDayAndMonth}`;
  }
  // same years but not current year
  return `${startDateDayAndMonth} - ${endDateDayAndMonth} ${endDate.year()}`;
};

export default function FormSearchSummarizedInput({ onClick }: Props) {
  const language = useLocale();
  const t = useTranslation();
  const theme = useTheme();
  const [selectedCityId, selectCityId] = useRecoilState(selectedCityIdAtom);
  const [selectedCityName, selectCityName] = useRecoilState(selectedCityNameAtom);
  const dateRange = useRecoilValue(dateRangeAtom);
  const dateRangeStr = formatDateRange(dateRange);
  const totalGuestCount = useRecoilValue(totalGuestsCounterSelector);

  const { data: cities } = useGetCities({ language, useShowOnSearchFilter: true });

  useEffect(() => {
    const cityName = selectedCityId != null ? cities?.find((city) => city.id === selectedCityId)?.title : undefined;
    selectCityName(cityName);
  }, [selectedCityId, cities, selectCityName]);

  useEffect(() => {
    if (selectedCityId != null && cities && cities.length > 0) {
      const relatedCity = cities?.find((city) => city.id === selectedCityId);
      if (!relatedCity) {
        selectCityId(undefined);
      }
    }
  }, [selectedCityId, cities, selectCityId]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          backgroundColor: "transparent",
          height: "44px",
        }}
      >
        <Button
          fullWidth
          onClick={onClick}
          size={"large"}
          color="inherit"
          data-testid="guest-amount-button"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            borderRadius: "8px",
            padding: "7px 12px 5px 12px",
            whiteSpace: "nowrap",
            color: "inherit",
            backgroundColor: alpha(theme.palette.grey[500], 0.08),
            "&:hover": {
              backgroundColor: "#919EAB34",
            },
          }}
          disableRipple
        >
          <Stack sx={{ display: "flex", flexDirection: "col", width: "100%" }}>
            <Typography
              align="left"
              sx={{ color: theme.palette.text.disabled, fontWeight: 400, fontSize: "12px", lineHeight: "12px" }}
            >
              {t("words_guest", {
                count: totalGuestCount,
              })}
              {", "}
              {dateRangeStr ?? t("datepicker_select_daterange_title")}
            </Typography>
            <Typography
              align="left"
              sx={{ color: "text.primary", fontWeight: 600, fontSize: "14px", lineHeight: "20px" }}
            >
              {selectedCityId ? selectedCityName : t("Common_allCities")}
            </Typography>
          </Stack>
          <Stack sx={{ paddingTop: "2px" }}>
            <DropdownIcon color={theme.palette.text.secondary} />
          </Stack>
        </Button>
      </Box>
    </>
  );
}
