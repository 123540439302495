import { useState } from "react";
import { useRouter } from "next/router";
import { Collapse } from "@mui/material";
import { useActiveLink } from "../../../../../fe-ui/hooks/use-active-link";
import { NavSectionVertical } from "../../../../components";
import { NavItemProps } from "../types";
import NavItem from "./NavItem";

type NavListProps = {
  item: NavItemProps;
};

export default function NavList({ item }: NavListProps) {
  const { pathname } = useRouter();
  const { path, children } = item;
  const { isExternalLink } = useActiveLink(path);
  const [open, setOpen] = useState(false);

  return (
    <>
      <NavItem
        item={item}
        open={open}
        onClick={() => setOpen(!open)}
        active={pathname === path}
        isExternalLink={isExternalLink}
      />

      {!!children && (
        <Collapse in={open} unmountOnExit>
          {!!children && <NavSectionVertical data={children} />}
        </Collapse>
      )}
    </>
  );
}
