import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";
import {
  selectedCityIdAtom,
  dateRangeAtom,
  filterSelectedAttributeIdsAtom,
  selectedHotelAtom,
  sortByPriceOrIsNewAtom,
} from "../state/search-state";
import { adultsCounterAtom, bookingReferralState, childrenCounterAtom } from "../state/booking-state";
import { parseFiltersParamToAttrIds } from "../utils/query-param-parsers";

export const useInitialStateFromQuery = () => {
  const router = useRouter();
  const setCityId = useSetRecoilState(selectedCityIdAtom);
  const setHotelId = useSetRecoilState(selectedHotelAtom);
  const setDateRange = useSetRecoilState(dateRangeAtom);
  const setSelectedAttrs = useSetRecoilState(filterSelectedAttributeIdsAtom);
  const setAdults = useSetRecoilState(adultsCounterAtom);
  const setChildren = useSetRecoilState(childrenCounterAtom);
  const setReferral = useSetRecoilState(bookingReferralState);
  const setSort = useSetRecoilState(sortByPriceOrIsNewAtom);

  const lastQueryRef = useRef(router.query); // Store the last query

  const updateStateFromQuery = (query) => {
    const { cityId, hotelId, arrivalDate, departureDate, filters, adults, children, referral, sort } = query;

    if (sort) {
      setSort(sort);
    }

    if (cityId && !Number.isNaN(Number(cityId))) {
      setCityId(Number(cityId));
    }

    if (hotelId && hotelId !== "null") {
      setHotelId(hotelId);
    }

    if (arrivalDate && departureDate && arrivalDate !== "null" && departureDate !== "null") {
      setDateRange([arrivalDate, departureDate]);
    }

    setSelectedAttrs(parseFiltersParamToAttrIds(filters));

    if (adults) {
      setAdults(Number(adults));
    }

    if (children) {
      setChildren(Number(children));
    }

    if (referral) {
      setReferral(referral);
    }
  };

  useEffect(() => {
    // Initial state update
    updateStateFromQuery(router.query);
    lastQueryRef.current = router.query;

    const handleRouteChange = (url) => {
      const newQuery = new URLSearchParams(url.split("?")[1]);
      const query = Object.fromEntries(newQuery.entries());

      // Only update state if the query has changed
      if (JSON.stringify(query) !== JSON.stringify(lastQueryRef.current)) {
        updateStateFromQuery(query);
        lastQueryRef.current = query; // Update the ref to the new query
      }
    };

    // Subscribe to route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
