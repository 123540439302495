import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import {
  Drawer,
  IconButton,
  Stack,
  Link as MuiLink,
  Button,
  Box,
  Typography,
  List,
  Link,
  ListItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NAV } from "../../../../../config-global";
import { Logo, Iconify, LanguagePopover } from "../../../../components/";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import { NavProps } from "../types";
import { useTranslation } from "../../../../../fe-ui/hooks/use-translation";
import { useAuth } from "react-oidc-context";
import { PROFILE_LINKS } from "../../../../components/ProfileDropdown/ProfileDropdown";
import NextLink from "next/link";
import NavList from "./NavList";

export default function NavMobile({ isOffset, data, isMainLayout }: NavProps) {
  const { pathname, locale } = useRouter();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const t = useTranslation();
  const auth = useAuth();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userFullName = auth.user?.profile?.name || "";
  const userEmail = auth.user?.profile?.email || "";

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
          width: 30,
          height: 30,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "15px",
          backgroundColor: theme.palette.background.default,
          zIndex: 1,
          ...(isOffset && {
            color: "text.primary",
          }),
        }}
      >
        <Iconify icon={"eva:menu-fill"} color={theme.palette.text.primary} />
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: isMainLayout ? NAV.W_MAIN_PAGE_MOBILE : NAV.W_BASE,
            backgroundColor: theme.palette.grey[100],
          },
        }}
      >
        <Scrollbar>
          <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
            <Logo isDark />
            <IconButton
              onClick={handleClose}
              sx={{
                width: 32,
                height: 32,
                boxShadow: "0px 12px 24px -4px #919EAB1F",
                borderRadius: "50%",
                backgroundColor: theme.palette.background.default,
                padding: "6px",
              }}
            >
              <Iconify icon={"eva:close-fill"} color={theme.palette.text.primary} />
            </IconButton>
          </Stack>

          {!isMainLayout && (
            <Box p={2}>
              <List component={"nav"} disablePadding>
                {data.map((link) => (
                  <NavList key={link.title} item={link} />
                ))}
              </List>
            </Box>
          )}

          {isMainLayout && (
            <>
              {auth.isAuthenticated && (
                <Stack gap={2} justifyContent="center" alignItems="center">
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: "64px",
                      height: "64px",
                      backgroundColor: theme.palette.background.default,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Iconify icon={"eva:person-fill"} color={theme.palette.text.secondary} width={32} height={32} />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {userFullName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                      color={theme.palette.text.secondary}
                    >
                      {userEmail}
                    </Typography>
                  </Box>
                  <List>
                    {PROFILE_LINKS.map((link) => (
                      <ListItem key={link.label}>
                        <Link
                          component={NextLink}
                          href={`/${locale}${link.href}`}
                          sx={{
                            color: theme.palette.text.primary,
                            flexGrow: 1,
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          {t(link.label)}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
              <Stack
                sx={{
                  padding: "24px 32px 40px 32px",
                }}
                gap={5}
              >
                <Stack spacing={1} direction="row" justifyContent="center">
                  <IconButton
                    sx={{
                      height: 32,
                      boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
                      borderRadius: "35px",
                      backgroundColor: theme.palette.background.default,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                      zIndex: 1,
                      color: "text.primary",
                      paddingX: "12px",
                      paddingY: "6px",
                      paddingLeft: "8px",
                    }}
                  >
                    <MuiLink
                      target="_blank"
                      href={`https://wa.me/${t("contacts_phone")}`}
                      color={theme.palette.text.primary}
                      data-ym={"MOBILE_NAV_ASK_QUESTION"}
                      sx={{
                        display: "inline-flex",
                        gap: 0.5,
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      <Iconify icon="eva:question-mark-circle-outline" />
                      {t("ask-question")}
                    </MuiLink>
                  </IconButton>
                  <LanguagePopover isOffset={isOffset} />
                </Stack>
                {auth?.isAuthenticated ? (
                  <Button
                    sx={{
                      height: 40,
                      color: theme.palette.error.main,
                    }}
                    variant="text"
                    onClick={() => auth.signoutSilent()}
                  >
                    {t("nav-cta-logout")}
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: 40,
                    }}
                    variant="contained"
                    onClick={() => auth.signinRedirect()}
                  >
                    {t("nav-cta-upload-unit")}
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Scrollbar>
      </Drawer>
    </>
  );
}
