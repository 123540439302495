import NextLink from "next/link";

import { MenuItem, Stack, IconButton, Link, Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { usePopover } from "../../../fe-ui/hooks/use-popover";
import { MenuPopover } from "../../../fe-ui/atoms/MenuPopover/MenuPopover";
import { Iconify } from "../../../fe-ui/atoms/Iconify";
import { useAuth } from "react-oidc-context";
import { CLIENT_ID } from "../../../@types/roleModel";
import { useRouter } from "next/router";

// ----------------------------------------------------------------------

type ProfileDropdownProps = {
  dataTestIds?: {
    button?: string;
    menuItem?: {
      profile: string;
      properties: string;
      bookings: string;
      logout: string;
    };
  };
};

export const PROFILE_LINKS = [
  {
    href: "/app/owner/profile-edit",
    label: "nav-edit-profile",
  },
  {
    href: "/app/owner/units",
    label: "nav-my-units",
  },
  {
    href: "/app/owner/reservations",
    label: "nav-my-reservations",
  },
];

export const ProfileDropdown = ({ dataTestIds }: ProfileDropdownProps) => {
  const auth = useAuth();

  const popover = usePopover();
  const t = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return null;
  }

  if (auth == null || !auth.isAuthenticated || auth.user?.profile.aud !== CLIENT_ID.owners) {
    return null;
  }

  const userEmail = auth.user?.profile.email;
  const userFullName = auth.user?.profile.name;

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          display: {
            md: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          },
          ml: 1,
          gap: 1,
          height: 32,
          width: 32,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "50%",
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
          },
          zIndex: 1,
          color: "text.secondary",
        }}
        data-testid={dataTestIds?.button}
      >
        <Iconify icon="eva:person-fill" />
      </IconButton>

      <MenuPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          width: 212,
          mt: 1,
          padding: 0,
        }}
      >
        <Box
          sx={{
            paddingY: "16px",
            paddingX: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {userFullName}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
            }}
            color={theme.palette.text.secondary}
          >
            {userEmail}
          </Typography>
        </Box>

        <Stack
          spacing={0.75}
          sx={{
            borderTop: "1px solid",
            borderBottom: "1px solid",
            borderColor: "#919EAB3D",
            padding: "8px",
          }}
        >
          {PROFILE_LINKS.map((link) => (
            <MenuItem
              key={link.label}
              sx={{
                padding: "0 !important",
                display: "inline-flex",
              }}
            >
              <Link
                component={NextLink}
                href={`/${router.locale}${link.href}`}
                underline={"none"}
                sx={{
                  color: theme.palette.text.primary,
                  paddingLeft: "16px",
                  paddingY: "6px",
                  flexGrow: 1,
                }}
              >
                {t(link.label)}
              </Link>
            </MenuItem>
          ))}
        </Stack>

        <Stack sx={{ padding: "8px" }}>
          <MenuItem
            onClick={() => {
              auth.signoutSilent();
            }}
            sx={{
              display: "inline-flex",
              color: theme.palette.error.main,
              fontSize: "14px",
              paddingLeft: "16px !important",
              paddingY: "6px",
            }}
          >
            {t("nav-cta-logout")}
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
};
