import React, { useEffect, useRef } from "react";
import { AuthProvider as AuthProviderBase, hasAuthParams, useAuth } from "react-oidc-context";
import { CircularProgress, Container } from "@mui/material";
import { useRouter } from "next/router";
import { env } from "../fe-ui/utils/env";
import { CLIENT_ID, USER_REALM } from "../@types/roleModel";

export const oidcConfig = {
  silent_redirect_uri: env("OIDC_SILENT_REDIRECT_URI"),
};

export const AuthProvider = ({
  children,
  realm,
  client_id,
}: {
  children: React.ReactNode;
  realm: USER_REALM;
  client_id: CLIENT_ID;
}) => {
  const { asPath: pathname, locale } = useRouter();

  if (realm === USER_REALM.mock) {
    return children;
  }

  return (
    <AuthProviderBase
      monitorSession={false}
      accessTokenExpiringNotificationTimeInSeconds={60}
      silentRequestTimeoutInSeconds={60}
      checkSessionIntervalInSeconds={60}
      includeIdTokenInSilentRenew={true}
      automaticSilentRenew={false}
      redirect_uri={env("PUBLIC_ORIGIN") + (`/${locale}${pathname}` || "/app")}
      authority={`${env("OIDC_AUTHORITY")}/${realm}/`}
      post_logout_redirect_uri={"/"}
      client_id={client_id}
      onSigninCallback={() => {
        // Erase sensitive authorization data, sent as URL query params after logging in via Keycloak.
        // It works by replacing the page, kept in history stack of the current tab, with the same page, but without query params.
        window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
      }}
      {...oidcConfig}
    >
      {children}
    </AuthProviderBase>
  );
};

const AuthContent = ({ children }) => {
  const timer = useRef<null | NodeJS.Timeout>(null);
  const auth = ((window as any).__auth = useAuth());
  const { isAuthenticated, signinRedirect } = auth;

  useEffect(() => {
    if (!auth.isLoading && auth.error && hasAuthParams()) {
      // If query contains invalid "code" or "state" param,
      // redirect user to same pathname, but without query params.
      window.location.replace(window.location.origin + window.location.pathname);
    }
  }, [auth.error, auth.isLoading]);

  useEffect(() => {
    timer.current && clearTimeout(timer.current);
    if (isAuthenticated === false) {
      timer.current = setTimeout(() => {
        signinRedirect();
      }, 2000);
    }
  }, [signinRedirect, isAuthenticated]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.startSilentRenew();
    });
  }, [auth.events]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <>{"Signing you in..."}</>;
    case "signoutRedirect":
      return <>{"Signing you out..."}</>;
  }

  if (auth.isLoading) {
    return (
      <Container maxWidth="sm">
        <CircularProgress size={100} />
      </Container>
    );
  }

  if (auth.error) {
    if (hasAuthParams()) {
      // If query contains invalid "code" or "state" param,
      // don't show any error message as user should not face
      // this error. Instead, in the active component, redirect
      // user to same pathname, but without query params.
      return <></>;
    }
    return <>Oops... {auth.error.message}</>;
  }

  if (auth.isAuthenticated) {
    return children;
  }

  return null;

  // return (
  //   <>
  //     <button onClick={() => auth.signinRedirect()}>{"Log in"}</button>
  //   </>
  // );
};

const AuthController = ({ children }) => {
  return <AuthContent>{children}</AuthContent>;
};

export { AuthController };
