export enum USER_REALM {
  "admin" = "admin",
  "master" = "master",
  "owners" = "owners",
  "mock" = "mock",
}

export enum CLIENT_ID {
  "admin" = "admin",
  "master" = "seaya",
  "owners" = "owner-account",
  "mock" = "mock",
}

export enum AUTHORIZED_USER_ROLE {
  "admin" = "admin",
  "booker" = "booker",
  "owner" = "owner",
}
